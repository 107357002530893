<template>
  <div :class="$style.reports">
    <div :class="$style.date">
      <b-field label="Date range">
        <date-range-picker
          v-model="dateFilter"
          :ranges="ranges"
          opens="left"
          @update="onDateChange"
        >
          <div
            slot="input"
            slot-scope="picker"
          >
            {{ formatDate({ date: picker.startDate, format: 'DD/MM/YYYY' }) }} -
            {{ formatDate({ date: picker.endDate, format: 'DD/MM/YYYY' }) }}
          </div>
        </date-range-picker>
      </b-field>
    </div>

    <div :class="$style.graph">
      <vue-apex-charts
        height="300"
        type="bar"
        :options="chartOptions"
        :series="series"
      />
    </div>

    <div
      v-if="showTable"
    >
      <div :class="$style.export">
        <b-button
          type="is-light"
          @click="exportCsv"
        >
          Export to csv
        </b-button>
      </div>

      <div :class="$style.table">
        <b-table :data="table">
          <b-table-column
            v-slot="{ row }"
            field="date"
            label="Date"
            sortable
          >
            {{ formatDate({ date: row.date, format: 'DD/MM/YYYY hh:mm:ss' }) }}
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            field="action"
            label="Action"
            sortable
          >
            {{ row.action }}
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            field="status"
            label="Status"
            sortable
          >
            {{ row.status }}
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            field="Credits"
            :label="creditsName"
            sortable
          >
            {{ row.credits }}
          </b-table-column>

          <b-table-column
            v-slot="{ row }"
            label="JobId"
          >
            {{ row.jobId }}
          </b-table-column>
        </b-table>
      </div>

      <div
        v-if="tableContinuationToken"
        :class="$style.more"
      >
        <b-button
          type="is-primary"
          :loading="loadingMore"
          @click="loadMore"
        >
          Load more
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import VueApexCharts from 'vue-apexcharts';
import DateRangePicker from 'vue2-daterange-picker';
import { mapState, mapActions } from 'pinia';
import { useReportsStore, useHelpStore, useAppStore } from '@/stores/index.js';
import { formatDate, capitalize } from '@/utils/index.js';

export default {
  name: 'Reports',
  components: {
    VueApexCharts,
    DateRangePicker,
  },
  data() {
    return {
      dateFilter: {
        startDate: dayjs().startOf('day').subtract(7, 'days'),
        endDate: dayjs().endOf('day'),
      },
      // toDate needed because for some reason DateRangePicker buggy with raw dayjs dates in ranges
      ranges: {
        Today: [
          dayjs().startOf('day').toDate(),
          dayjs().endOf('day').toDate(),
        ],
        'This week': [
          dayjs().startOf('week').toDate(),
          dayjs().endOf('week').toDate(),
        ],
        'Last week': [
          dayjs().subtract(1, 'week').startOf('week').toDate(),
          dayjs().subtract(1, 'week').endOf('week').toDate(),
        ],
        'This month': [
          dayjs().startOf('month').toDate(),
          dayjs().endOf('month').toDate(),
        ],
        'Last month': [
          dayjs().subtract(1, 'month').startOf('month').toDate(),
          dayjs().subtract(1, 'month').endOf('month').toDate(),
        ],
        'This year': [
          dayjs().startOf('year').toDate(),
          dayjs().endOf('year').toDate(),
        ],
      },
      loadingMore: false,
    };
  },
  computed: {
    ...mapState(useReportsStore, ['table', 'chart', 'tableContinuationToken']),
    ...mapState(useAppStore, ['getProduct']),

    showTable() {
      return this.table?.length;
    },
    creditsName() {
      if (this.getProduct === 'actions') return 'Executions';

      return capitalize(this.getProduct);
    },
    from() {
      return dayjs(this.dateFilter.startDate).startOf('day').toISOString();
    },
    to() {
      return dayjs(this.dateFilter.endDate).endOf('day').toISOString();
    },
    reportOptions() {
      return {
        product: this.getProduct,
        from: this.from,
        to: this.to,
      };
    },
    categories() {
      return this.chart?.categories || [];
    },
    series() {
      return this.chart?.series || [];
    },
    isEmptySeries() {
      return !this.series.length;
    },
    chartOptions() {
      return {
        colors: [
          '#66c2a5',
          '#fc8d62',
          '#8da0cb',
          '#e78ac3',
          '#a6d854',
          '#e5c494',
          '#b3b3b3',
        ],
        fill: {
          opacity: 1,
        },
        chart: {
          id: 'reports-chart',
          stacked: true,
          height: 350,
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 200,
            animateGradually: {
              enabled: true,
              delay: 50,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 150,
            },
          },
        },
        noData: {
          text: 'No data available for the selected period',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '34px',
          },
        },
        xaxis: {
          type: 'category',
          labels: {
            formatter: (value) => {
              const daysDiff = Math.floor(
                (Date.parse(this.to) - Date.parse(this.from)) / 86400000,
              );
              const monthlyFormat = { month: 'short' };
              const dailyFormat = { month: 'short', day: 'numeric' };

              try {
                return new Date(value).toLocaleDateString('en-US', daysDiff > 30 ? monthlyFormat : dailyFormat);
              } catch {
                return value;
              }
            },
          },
          categories: this.categories,
          position: 'bottom',
        },
        yaxis: {
          title: {
            text: this.creditsName,
          },
          min: 0,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: 'bottom',
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              noData: {
                style: {
                  fontSize: '26px',
                },
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              noData: {
                style: {
                  fontSize: '14px',
                },
              },
            },
          },
        ],
        grid: {
          position: this.isEmptySeries ? 'front' : 'back',
        },
      };
    },
  },
  async created() {
    await Promise.all([this.readChart(this.reportOptions), this.readTable(this.reportOptions)]);

    if (this.getProduct === 'documents') this.setHelpOpen(true);
  },
  beforeDestroy() {
    this.setHelpOpen(false);
  },
  methods: {
    ...mapActions(useReportsStore, ['readChart', 'readTable', 'readCsv']),
    ...mapActions(useHelpStore, { setHelpOpen: 'setOpen' }),
    formatDate,
    onDateChange() {
      this.readChart(this.reportOptions);
      this.readTable(this.reportOptions);
    },
    async loadMore() {
      this.loadingMore = true;

      await this.readTable({
        ...this.reportOptions,
        continuationToken: this.tableContinuationToken,
      });

      this.loadingMore = false;
    },
    exportCsv() {
      this.readCsv(this.reportOptions);
    },
  },
};
</script>

<style lang="scss" module>
.reports {
  .graph,
  .table {
    margin-bottom: 20px;
  }

  .date,
  .export {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .more {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .reports {
    :global(.table-mobile-sort) {
      display: none;
    }
  }
}
</style>
